<template>
    <div id='blackfr'>
        <BFMain/>
        
        <div class="BF-cont" :class='$mq'>
            <BFItem/>
        </div>
    </div>    
</template>

<script>
    import BFMain from './BFMainScreen.vue'
    import BFItem from './BFItem.vue'

    export default {
        name: 'BFHome',
        components: {
            BFMain,
            BFItem
        }
    }
</script>

<style>
    .BF {
        background-color: #17122d;
        color: white;
    }

    .BF .h-logo{
        color: white;
    }

    .BF header {
        margin-bottom:0px;
    }

    .BF-cont {
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;        
        flex-wrap: wrap;
        padding: 0 60px;
    }
        .BF-cont.mobile {
         padding: 0 20px;   
        }

    .BF footer a {
        color: #d98c6b;
    }
        .BF footer a::after {
            border-top: 1px solid #d98c6b;
        }

        .BF footer a.h-logo::after{
            border: none;
        }

        .BF footer .ft-soc::after {
            border: none;
        }
</style>